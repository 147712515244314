<template>
  <b-overlay
    :show="show"
    variant="transparent"
    opacity="0.99"
    blur="5px"
    rounded="sm"
  >
    <template #overlay>
      <div class="text-center">
        <b-icon-controller
          font-scale="3"
          animation="cylon"
        />
        <p id="cancel-label">
          กรุณารอสักครู่...
        </p>
      </div>
    </template>
    <div>
      <div class="d-flex align-items-center justify-content-end">
        <b-button
          variant="gradient-primary"
          @click="submit"
        >
          <i
            class="fad fa-cloud-download-alt"
            style="color: #fff"
          />
          บันทึกข้อมูล
        </b-button>
      </div>
      <b-row class="match-height mt-1">
        <b-col md="12">
          <div class="ag-format-container">
            <div class="ag-courses_box">
              <div class="ag-courses_item">
                <a
                  href="#"
                  class="ag-courses-item_link"
                >
                  <div class="ag-courses-item_bg" />

                  <div class="ag-courses-item_title">ปิด-เปิด หน้าฝาก</div>
                  <div>
                    <b-form-checkbox
                      v-model="agent.status_dip"
                      class="custom-control-success"
                      name="check-button"
                      switch
                    >
                      <span class="switch-icon-left">
                        <feather-icon icon="CheckIcon" />
                      </span>
                      <span class="switch-icon-right">
                        <feather-icon icon="XIcon" />
                      </span>
                    </b-form-checkbox>
                  </div>

                  <div class="ag-courses-item_date-box">
                    สถานะ:
                    <span class="ag-courses-item_date">
                      {{ agent.status_dip ? "กำลังเปิดใช้งาน" : "ปิดใช้งาน" }}
                    </span>
                  </div>
                </a>
              </div>

              <div class="ag-courses_item">
                <a
                  href="#"
                  class="ag-courses-item_link"
                >
                  <div class="ag-courses-item_bg" />

                  <div class="ag-courses-item_title">ปิด-เปิด หน้าถอน</div>
                  <div>
                    <b-form-checkbox
                      v-model="agent.status_wit"
                      class="custom-control-success"
                      name="check-button"
                      switch
                    >
                      <span class="switch-icon-left">
                        <feather-icon icon="CheckIcon" />
                      </span>
                      <span class="switch-icon-right">
                        <feather-icon icon="XIcon" />
                      </span>
                    </b-form-checkbox>
                  </div>
                  <div class="ag-courses-item_date-box">
                    สถานะ:
                    <span class="ag-courses-item_date">  {{ agent.status_wit ? "กำลังเปิดใช้งาน" : "ปิดใช้งาน" }} </span>
                  </div>
                </a>
              </div>

              <div class="ag-courses_item">
                <a
                  href="#"
                  class="ag-courses-item_link"
                >
                  <div class="ag-courses-item_bg" />

                  <div class="ag-courses-item_title">
                    ปิด-เปิด ฝากออโต้ statement
                  </div>
                  <div>
                    <b-form-checkbox
                      v-model="agent.status_auto_dip"
                      class="custom-control-success"
                      name="check-button"
                      switch
                    >
                      <span class="switch-icon-left">
                        <feather-icon icon="CheckIcon" />
                      </span>
                      <span class="switch-icon-right">
                        <feather-icon icon="XIcon" />
                      </span>
                    </b-form-checkbox>
                  </div>
                  <div class="ag-courses-item_date-box">
                    สถานะ:
                    <span class="ag-courses-item_date"> {{ agent.status_auto_dip ? "กำลังเปิดใช้งาน" : "ปิดใช้งาน" }} </span>
                  </div>
                </a>
              </div>

              <div class="ag-courses_item">
                <a
                  href="#"
                  class="ag-courses-item_link"
                >
                  <div class="ag-courses-item_bg" />

                  <div class="ag-courses-item_title">ปิด-เปิด ถอนออโต้</div>
                  <div>
                    <b-form-checkbox
                      v-model="agent.status_auto_wit"
                      class="custom-control-success"
                      name="check-button"
                      switch
                    >
                      <span class="switch-icon-left">
                        <feather-icon icon="CheckIcon" />
                      </span>
                      <span class="switch-icon-right">
                        <feather-icon icon="XIcon" />
                      </span>
                    </b-form-checkbox>
                  </div>
                  <div class="ag-courses-item_date-box">
                    สถานะ:
                    <span class="ag-courses-item_date"> {{ agent.status_auto_wit ? "กำลังเปิดใช้งาน" : "ปิดใช้งาน" }} </span>
                  </div>
                </a>
              </div>

              <div class="ag-courses_item">
                <a
                  href="#"
                  class="ag-courses-item_link"
                >
                  <div class="ag-courses-item_bg" />

                  <div class="ag-courses-item_title">ปิด-เปิด ฝากออโต้ SMS</div>
                  <div>
                    <b-form-checkbox
                      v-model="agent.dep_sms"
                      class="custom-control-success"
                      name="check-button"
                      switch
                    >
                      <span class="switch-icon-left">
                        <feather-icon icon="CheckIcon" />
                      </span>
                      <span class="switch-icon-right">
                        <feather-icon icon="XIcon" />
                      </span>
                    </b-form-checkbox>
                  </div>
                  <div class="ag-courses-item_date-box">
                    สถานะ:
                    <span class="ag-courses-item_date"> {{ agent.dep_sms ? "กำลังเปิดใช้งาน" : "ปิดใช้งาน" }} </span>
                  </div>
                </a>
              </div>

              <div class="ag-courses_item">
                <a
                  href="#"
                  class="ag-courses-item_link"
                >
                  <div class="ag-courses-item_bg" />

                  <div class="ag-courses-item_title">
                    ลบลูกค้าที่ไม่ได้เข้าระบบ 90 วันที่ผ่านมา
                  </div>
                  <div>
                    <b-form-checkbox
                      v-model="agent.clearuser"
                      class="custom-control-success"
                      name="check-button"
                      switch
                    >
                      <span class="switch-icon-left">
                        <feather-icon icon="CheckIcon" />
                      </span>
                      <span class="switch-icon-right">
                        <feather-icon icon="XIcon" />
                      </span>
                    </b-form-checkbox>
                  </div>
                  <div class="ag-courses-item_date-box">
                    สถานะ:
                    <span class="ag-courses-item_date"> {{ agent.clearuser ? "กำลังเปิดใช้งาน" : "ปิดใช้งาน" }} </span>
                  </div>
                </a>
              </div>

              <div class="ag-courses_item">
                <a
                  href="#"
                  class="ag-courses-item_link"
                >
                  <div class="ag-courses-item_bg" />

                  <div class="ag-courses-item_title">ปิด-เปิด Maintenance</div>
                  <div>
                    <b-form-checkbox
                      v-model="agent.maintenance"
                      class="custom-control-success"
                      name="check-button"
                      switch
                    >
                      <span class="switch-icon-left">
                        <feather-icon icon="CheckIcon" />
                      </span>
                      <span class="switch-icon-right">
                        <feather-icon icon="XIcon" />
                      </span>
                    </b-form-checkbox>
                  </div>
                  <div class="ag-courses-item_date-box">
                    สถานะ:
                    <span class="ag-courses-item_date"> {{ agent.maintenance ? "กำลังเปิดใช้งาน" : "ปิดใช้งาน" }} </span>
                  </div>
                </a>
              </div>

              <div class="ag-courses_item">
                <a
                  href="#"
                  class="ag-courses-item_link"
                >
                  <div class="ag-courses-item_bg" />

                  <div class="ag-courses-item_title">เสียงแจ้งเตือน</div>
                  <div>
                    <b-form-checkbox
                      v-model="agent.alarm"
                      class="custom-control-success"
                      name="check-button"
                      switch
                    >
                      <span class="switch-icon-left">
                        <feather-icon icon="CheckIcon" />
                      </span>
                      <span class="switch-icon-right">
                        <feather-icon icon="XIcon" />
                      </span>
                    </b-form-checkbox>
                  </div>
                  <div class="ag-courses-item_date-box">
                    สถานะ:
                    <span class="ag-courses-item_date"> {{ agent.alarm ? "กำลังเปิดใช้งาน" : "ปิดใช้งาน" }} </span>
                  </div>
                </a>
              </div>

              <div class="ag-courses_item">
                <a
                  href="#"
                  class="ag-courses-item_link"
                >
                  <div class="ag-courses-item_bg" />

                  <div class="ag-courses-item_title">
                    ตรวจสอบชื่อตรงกับบัญชี(สมัคร)
                  </div>
                  <div>
                    <b-form-checkbox
                      v-model="agent.status_checkname"
                      class="custom-control-success"
                      name="check-button"
                      switch
                    >
                      <span class="switch-icon-left">
                        <feather-icon icon="CheckIcon" />
                      </span>
                      <span class="switch-icon-right">
                        <feather-icon icon="XIcon" />
                      </span>
                    </b-form-checkbox>
                  </div>
                  <div class="ag-courses-item_date-box">
                    สถานะ:
                    <span class="ag-courses-item_date"> {{ agent.status_checkname ? "กำลังเปิดใช้งาน" : "ปิดใช้งาน" }} </span>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </b-col>

        <b-col md="6">
          <b-card>
            <h3><i class="fad fa-gifts" /> ระบบถอนออโต้</h3>
            <b-form-group
              label="เลือกระบบถอน"
              label-for="customDelimiter"
            >
              <v-select
                v-model="agent.autowithdraw"
                label="text"
                :options="autolist"
                :reduce="(text) => text.value"
              />
            </b-form-group>

            <!-- <b-button
              v-if="agent.autowithdraw == 'scb'"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="px-4 btn-icon"
              @click="showModal(bank = 'withdraw')"
            >
              เชื่อมออโต้ Scb
            </b-button> -->

            <!-- <b-button
              v-if="agent.autowithdraw == 'kbank'"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="success"
              class="px-4 btn-icon"
              @click="showModalkbank(service_type_kbank = 'withdraw')"
            >
              เชื่อมออโต้ kbank
            </b-button>

            <div v-if="agent.autowithdraw == 'kbank' && agent.accno_use_kbank && agent.name_use_kbank">
              <br>
              <h5>บัญชีที่ใช้ถอนปัจจุบัน</h5>
              <p>เลขบัญชี : {{ agent.accno_use_kbank }}</p>
              <p>ชื่อบัญชี : {{ agent.name_use_kbank }}</p>
            </div> -->

            <b-button
              v-if="agent.autowithdraw == 'kbank'"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="success"
              class="px-4 btn-icon"
              @click="showModalkbankApi()"
            >
              เชื่อมออโต้ kbank
            </b-button>
            <b-button
              v-if="agent.autowithdraw == 'krungsri'"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="warning"
              class="px-4 btn-icon"
              @click="showModalKrungsriWithdraw()"
            >
              เชื่อมออโต้กรุงศรี Biz
            </b-button>

            <!-- <b-form-group
              label="เปิด / ปิด ระบบออโต้"
              label-for="customDelimiter"
            >
              <b-form-checkbox
                v-model="KbankApi.status"
                class="custom-control-success"
                name="check-button"
                :value="1"
                :unchecked-value="0"
                switch
              >
                <span class="switch-icon-left">
                  <feather-icon icon="CheckIcon" />
                </span>
                <span class="switch-icon-right">
                  <feather-icon icon="XIcon" />
                </span>
              </b-form-checkbox>
            </b-form-group> -->

            <div
              v-if="
                agent.autowithdraw == 'kbank' &&
                  KbankApi.username &&
                  KbankApi.password
              "
            >
              <br>
              <h5>บัญชีที่ใช้ถอนปัจจุบัน</h5>
              <p>เลขบัญชี : {{ KbankApi.acc_no }}</p>
              <p>ชื่อบัญชี : {{ KbankApi.acc_name }}</p>
              <p>เบอร์โทร : {{ KbankApi.phone }}</p>
              <p>Kbiz username : {{ KbankApi.username }}</p>
              <p>Kbiz password : {{ KbankApi.password }}</p>
            </div>

            <div v-if="agent.autowithdraw == 'krungsri' && Krungsri.username && Krungsri.password">
              <br>
              <h5>บัญชีที่ใช้ถอนปัจจุบัน</h5>
              <p>เลขบัญชี : {{ Krungsri.acc_no }}</p>
              <p>ชื่อบัญชี : {{ Krungsri.acc_name }}</p>
              <p>เบอร์โทร : {{ Krungsri.phone }}</p>
              <p>Username : {{ Krungsri.username }}</p>
              <p>Password : {{ Krungsri.password }}</p>
            </div>

            <div v-if="agent.autowithdraw == 'scb'">
              <h5>บัญชีที่ใช้ถอนปัจจุบัน</h5>
              <p>เลขบัญชี : {{ agent.accno_use_scb }}</p>
              <p>ชื่อบัญชี : {{ agent.name_use_scb }}</p>
              <b-form-group
                label="เลือกบัญชีถอน"
                label-for="customDelimiter"
              >
                <v-select
                  v-model="wit_scb_id"
                  label="text"
                  :options="wit_scb_list"
                  :reduce="(text) => text.id"
                />
              </b-form-group>
              <br>
            </div>
          </b-card>
        </b-col>

        <b-col md="6">
          <b-card>
            <h3><i class="fad fa-gifts" /> จำนวนเทิร์นที่ลูกค้าไม่รับโปร</h3>
            <b-form-group
              label="จำนวนเทิร์น"
              label-for="customDelimiter"
            >
              <b-form-input
                id="floating-label2"
                v-model="agent.turn_nopro"
                type="number"
                placeholder="จำนวนเทิร์น"
              />
            </b-form-group>
          </b-card>
        </b-col>
        <b-col md="6">
          <b-card>
            <h3>
              <i class="fad fa-donate" /> ยอดถอนเครดิตสูงสุด
              <small>(โดยไม่ต้อง approve)</small>
            </h3>
            <b-form-group
              label="ถอนเครดิตสูงสุด"
              label-for="customDelimiter"
            >
              <b-form-input
                id="floating-label2"
                v-model="agent.withdraw_approve"
                type="number"
                placeholder="ถอนเครดิตสูงสุด"
              />
            </b-form-group>
          </b-card>
        </b-col>
        <b-col md="6">
          <b-card>
            <h3><i class="fab fa-ethereum" /> ยอดถอนต่อครั้ง</h3>
            <b-form-group
              label="ถอนเครดิตต่ำสุด"
              label-for="customDelimiter"
            >
              <b-form-input
                id="floating-label2"
                v-model="agent.withdraw_min"
                type="number"
                placeholder="ถอนเครดิตต่ำสุด"
              />
            </b-form-group>
            <b-form-group
              label="ถอนเครดิตสูงสุด"
              label-for="customDelimiter"
            >
              <b-form-input
                id="floating-label2"
                v-model="agent.withdraw_max"
                type="number"
                placeholder="ถอนเครดิตสูงสุด"
              />
            </b-form-group>
          </b-card>
        </b-col>
        <!-- <b-col md="6">
          <b-card>
            <h3><i class="fab fa-ethereum" /> ยอดขั้นต่ำที่ถอนด้วยออโต้ ธนาคาร & Payment Gateway</h3>
            <b-form-group
              label="ขั้นต่ำที่จะถอนด้วย ธนาคาร"
              label-for="customDelimiter"
            >
              <b-form-input
                id="floating-label2"
                v-model="agent.minamount_with_bank"
                type="number"
                placeholder="ขั้นต่ำที่จะถอนด้วย ธนาคาร"
              />
            </b-form-group>
            <b-form-group
              label="ขั้นต่ำที่จะถอนด้วย payment"
              label-for="customDelimiter"
            >
              <b-form-input
                id="floating-label2"
                v-model="agent.minamount_with_payment"
                type="number"
                placeholder="ขั้นต่ำที่จะถอนด้วย payment"
              />
            </b-form-group>

            <b-form-group>
              <label>ปิด / เปิด ออโต้ Payment Gateway</label>
              <b-form-checkbox
                v-model="agent.autowithdraw_payment_status"
                class="custom-control-success"
                name="check-button"
                switch
              >
                <span class="switch-icon-left">
                  <feather-icon icon="CheckIcon" />
                </span>
                <span class="switch-icon-right">
                  <feather-icon icon="XIcon" />
                </span>
              </b-form-checkbox>
            </b-form-group>

            <br>
            <p>
              ยอดที่ถอนจาก ธนาคาร : {{ agent.minamount_with_bank }} - {{ agent.minamount_with_payment }} </p>
            ยอดที่ถอนจาก payment gateway : {{ agent.minamount_with_payment }} ขึ้นไป
            </p>
          </b-card>
        </b-col> -->
        <b-col md="6">
          <b-card>
            <h3><i class="fad fa-wallet" /> ตั้งค่าส่ง SMS หลังลูกค้าสมัคร (Thai Bulk)</h3>
            <b-form-group
              label="sender"
              label-for="customDelimiter"
            >
              <b-form-input
                id="floating-label2"
                v-model="agent.thaibulk_sender"
                placeholder="ชื่อผู้ส่ง"
              />
            </b-form-group>
            <b-form-group
              label="ข้อความ (70 ตัวอักษร)"
              label-for="customDelimiter"
            >
              <b-form-input
                id="floating-label2"
                v-model="agent.thaibulk_message"
                placeholder="ข้อความ"
              />
            </b-form-group>
            <b-form-group
              label="API Key"
              label-for="customDelimiter"
            >
              <b-form-input
                id="floating-label2"
                v-model="agent.thaibulk_key"
                placeholder="Key"
              />
            </b-form-group>
            <b-form-group
              label="API Secret"
              label-for="customDelimiter"
            >
              <b-form-input
                id="floating-label2"
                v-model="agent.thaibulk_secret"
                placeholder="Secret"
              />
            </b-form-group>
          </b-card>
        </b-col>
        <b-col md="6">
          <b-card>
            <h3><i class="fad fa-wallet" /> จัดการ Line</h3>
            <b-form-group
              label="ไลน์ Contact หน้าเว็บ wallet"
              label-for="customDelimiter"
            >
              <b-form-input
                id="linecontact"
                v-model="agent.linecontact"
                placeholder="ไลน์ Contact หน้าเว็บ wallet"
              />
            </b-form-group>
            <b-form-group
              label="ไลน์ Token ฝาก"
              label-for="linetoken_dip"
            >
              <b-form-input
                id="linetoken_dip"
                v-model="agent.linetoken_dip"
                placeholder="ไลน์ Token ฝาก"
              />
            </b-form-group>
            <b-form-group
              label="ไลน์ Token ถอน"
              label-for="linetoken_wit"
            >
              <b-form-input
                id="linetoken_wit"
                v-model="agent.linetoken_wit"
                placeholder="ไลน์ Token ฝาก"
              />
            </b-form-group>
          </b-card>
        </b-col>

        <b-col md="6">
          <b-card>
            <h3><i class="fad fa-wallet" /> จัดการข้อมูลส่ง SMS (THSMS)</h3>
            <b-form-group
              label="ยูสเซอร์เนม"
              label-for="customDelimiter"
            >
              <b-form-input
                id="linecontact"
                v-model="agent.thssms_user"
                placeholder="ยูสเซอร์เนม"
              />
            </b-form-group>
            <b-form-group
              label="พาสเวิร์ด"
              label-for="linetoken_dip"
            >
              <b-form-input
                id="linetoken_dip"
                v-model="agent.thssms_pass"
                placeholder="พาสเวิร์ด"
              />
            </b-form-group>
            <b-form-group
              label="API-KEY"
              label-for="linetoken_wit"
            >
              <b-form-input
                id="thssms_apikey"
                v-model="agent.thssms_apikey"
                placeholder="API-KEY"
              />
            </b-form-group>
          </b-card>
        </b-col>

        <b-col md="6">
          <b-card>
            <h3><i class="fad fa-megaphone" /> ข้อความประกาศ</h3>
            <b-form-group
              label="เพิ่มข้อความประกาศ"
              label-for="anounce"
            >
              <b-form-textarea
                id="anounce"
                v-model="agent.announce"
                rows="5"
              />
            </b-form-group>
          </b-card>
        </b-col>

        <b-col md="6">
          <b-card>
            <h3><i class="fab fa-ethereum" />เติมมือต่อครั้ง</h3>
            <b-form-group
              label="แอดมินเติมมือสูงสุด (ไทย)"
              label-for="customDelimiter"
            >
              <b-form-input
                id="floating-label2"
                v-model="agent.max_manual_dep_level1"
                type="number"
                placeholder="เติมมือสูงสุด"
              />
            </b-form-group>
            <b-form-group
              label="ซุปเปอร์แอดมินเติมมือสูงสุด (ไทย)"
              label-for="customDelimiter"
            >
              <b-form-input
                id="floating-label2"
                v-model="agent.max_manual_dep_level2"
                type="number"
                placeholder="เติมมือสูงสุด"
              />
            </b-form-group>
            <b-form-group
              label="แอดมินเติมมือสูงสุด (เกาหลี)"
              label-for="customDelimiter"
            >
              <b-form-input
                id="floating-label2"
                v-model="agent.max_manual_depkr_level1"
                type="number"
                placeholder="เติมมือสูงสุด"
              />
            </b-form-group>
            <b-form-group
              label="ซุปเปอร์แอดมินเติมมือสูงสุด (เกาหลี)"
              label-for="customDelimiter"
            >
              <b-form-input
                id="floating-label2"
                v-model="agent.max_manual_depkr_level2"
                type="number"
                placeholder="เติมมือสูงสุด"
              />
            </b-form-group>
          </b-card>
        </b-col>
      </b-row>

      <b-modal
        ref="my-modal-kbank"
        title="กรุณากรอกข้อมูล"
        size="sm"
        ok-title="ตกลง"
        cancel-title="ยกเลิก"
        no-close-on-backdrop
        @ok="SubmitGenKbank()"
      >
        <b-form-group>
          <label
            for="dividend"
          >ชื่อบัญชี
            <span
              class="text-danger"
            >* ชื่อ - นามสกุลเจ้าของบัญชี
            </span></label>
          <b-input-group>
            <b-form-input
              v-model="acc_name_kbank"
              type="text"
            />
          </b-input-group>
        </b-form-group>

        <b-form-group
          label="เลขบัญชี (ไม่ต้องมี - )"
          label-for="acc_kbank"
        >
          <b-form-input
            id="acc_kbank"
            v-model="acc_kbank"
            placeholder="เลขบัญชีถอนกสิกร"
          />
        </b-form-group>

        <b-form-group
          label="PIN (รหัส App K Plus)"
          label-for="pin_kbank"
        >
          <b-form-input
            id="pin_kbank"
            v-model="pin_kbank"
            placeholder="รหัส App K Plus"
            type="Number"
            maxlength="6"
          />
        </b-form-group>

        <b-form-group
          label="ข้อมูลที่ Gen ผ่าน App"
          label-for="gen_kbank"
        >
          <b-form-textarea
            id="gen_kbank"
            v-model="gen_kbank"
            placeholder="ข้อมูลที่ Gen ผ่าน App"
            type="text"
          />
        </b-form-group>
      </b-modal>
      <b-modal
        ref="modal-kbank-api"
        title="กรุณากรอกข้อมูล"
        size="sm"
        ok-title="ตกลง"
        cancel-title="ยกเลิก"
        no-close-on-backdrop
        @ok="SubmitGenKbankApi()"
      >
        <b-form-group>
          <label
            for="dividend"
          >ชื่อบัญชี
            <span
              class="text-danger"
            >* ชื่อ - นามสกุลเจ้าของบัญชี
            </span></label>
          <b-input-group>
            <b-form-input
              v-model="KbankApi.acc_name"
              type="text"
            />
          </b-input-group>
        </b-form-group>

        <b-form-group
          label="เลขบัญชี (ไม่ต้องมี - )"
          label-for="acc_kbank"
        >
          <b-form-input
            id="acc_kbank"
            v-model="KbankApi.acc_no"
            placeholder="เลขบัญชีถอนกสิกร"
          />
        </b-form-group>

        <b-form-group
          label="เบอร์โทร (ไม่ต้องมี - )"
          label-for="Kbiz_phone"
        >
          <b-form-input
            id="Kbiz_phone"
            v-model="KbankApi.phone"
            placeholder="เบอร์โทร"
          />
        </b-form-group>

        <b-form-group
          label="Kbiz username"
          label-for="Kbiz_Username"
        >
          <b-form-input
            id="Kbiz_Username"
            v-model="KbankApi.username"
            placeholder="Kbiz Username"
          />
        </b-form-group>

        <b-form-group
          label="Kbiz password"
          label-for="Kbiz_password"
        >
          <b-form-input
            id="Kbiz_password"
            v-model="KbankApi.password"
            placeholder="Kbiz password"
          />
        </b-form-group>
      </b-modal>

      <b-modal
        ref="modal-krungsri-withdraw"
        title="กรุณากรอกข้อมูล"
        size="sm"
        ok-title="ตกลง"
        cancel-title="ยกเลิก"
        no-close-on-backdrop
        @ok="SubmitKrungsriwitdraw()"
      >
        <b-form-group>
          <label for="dividend">ชื่อบัญชี <span class="text-danger">* ชื่อ - นามสกุลเจ้าของบัญชี </span></label>
          <b-input-group>
            <b-form-input
              v-model="Krungsri.acc_name"
              type="text"
            />
          </b-input-group>
        </b-form-group>

        <b-form-group
          label="เลขบัญชี (ไม่ต้องมี - )"
          label-for="acc_kbank"
        >
          <b-form-input
            id="acc_kbank"
            v-model="Krungsri.acc_no"
            placeholder="เลขบัญชีถอนกสิกร"
          />
        </b-form-group>

        <b-form-group
          label="เบอร์โทร (ไม่ต้องมี - )"
          label-for="Kbiz_phone"
        >
          <b-form-input
            id="Kbiz_phone"
            v-model="Krungsri.phone"
            placeholder="เบอร์โทร"
          />
        </b-form-group>

        <b-form-group
          label="Username"
          label-for="Kbiz_Username"
        >
          <b-form-input
            id="Kbiz_Username"
            v-model="Krungsri.username"
            placeholder="Username"
          />
        </b-form-group>

        <b-form-group
          label="Password"
          label-for="Kbiz_password"
        >
          <b-form-input
            id="Kbiz_password"
            v-model="Krungsri.password"
            placeholder="Password"
          />
        </b-form-group>

      </b-modal>

      <b-modal
        ref="my-modal"
        title="กรุณากรอกข้อมูล"
        size="sm"
        ok-title="ตกลง"
        cancel-title="ยกเลิก"
        no-close-on-backdrop
        @ok="GetDeviceIdScb()"
      >
        <div v-if="step == 1">
          <b-form-group>
            <label
              for="dividend"
            >ชื่อบัญชี
              <span
                class="text-danger"
              >* ชื่อ - นามสกุลเจ้าของบัญชี
              </span></label>
            <b-input-group>
              <b-form-input
                v-model="acc_name_scb"
                type="text"
              />
            </b-input-group>
          </b-form-group>

          <b-form-group>
            <label
              for="dividend"
            >เลขบัตรประชาชน <span class="text-danger">* ไม่มี - </span></label>
            <b-input-group prepend="ไม่ต้องมี -">
              <b-form-input
                v-model="cardId"
                type="number"
              />
            </b-input-group>
          </b-form-group>

          <b-form-group>
            <label
              for="dividend"
            >วัน / เดือน / ปีเกิด (คศ.)
              <span class="text-danger">*(ตัวอย่าง 1999-01-01)</span></label>
            <b-input-group prepend="1999-01-01">
              <b-form-input v-model="dateOfBirth" />
            </b-input-group>
          </b-form-group>

          <b-form-group>
            <label
              for="dividend"
            >เบอร์โทร <span class="text-danger">* ไม่มี -</span></label>
            <b-input-group prepend="ไม่ต้องมี -">
              <b-form-input
                v-model="MobilePhoneNo"
                type="number"
              />
            </b-input-group>
          </b-form-group>
        </div>
        <div v-if="step == 2">
          <b-form-group>
            <label for="dividend">
              เลขที่บัญชี <span class="text-danger">* ไม่มี -</span></label>
            <b-input-group prepend="">
              <b-form-input
                v-model="acc_no"
                type="number"
              />
            </b-input-group>
          </b-form-group>

          <b-form-group>
            <label for="dividend">
              PIN <span class="text-danger">*</span></label>
            <b-input-group prepend="">
              <b-form-input
                v-model="pin"
                type="number"
              />
            </b-input-group>
          </b-form-group>

          <b-form-group>
            <label for="dividend">
              OTP <span class="text-danger">* (ไม่เกิน 5 นาที)</span></label>
            <b-input-group prepend="">
              <b-form-input
                v-model="otp"
                type="number"
              />
            </b-input-group>
          </b-form-group>
        </div>
      </b-modal>

      <b-modal
        ref="money-rocker"
        hide-footer
        hide-header
        title="โยกเงินอัตโนมัติ"
        centered
      >
        <div class="text-center">
          <img
            src="confirm.png"
            alt="confirm"
            height="80"
          >
          <h5>{{ ShowCheckBank.bankName }}</h5>
          <h4>{{ ShowCheckBank.acc }}</h4>
          <h5>{{ ShowCheckBank.name }}</h5>

          <b-button
            variant="gradient-primary"
            size="sm"
            pill
            @click="hide()"
          >
            ปิดหน้าต่าง
          </b-button>
        </div>
      </b-modal>
    </div>
  </b-overlay>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BButton,
  BTab,
  BFormCheckbox,
  BTabs,
  BFormTextarea,
  BModal,
  BOverlay,
  BIconController,
  BInputGroup,
  BInputGroupAppend,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Cleave from 'vue-cleave-component'
// eslint-disable-next-line import/no-extraneous-dependencies
import 'cleave.js/dist/addons/cleave-phone.us'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BModal,
    BCard,
    BRow,
    BCol,
    Cleave,
    BFormGroup,
    vSelect,
    BFormInput,
    BFormCheckbox,
    BButton,
    BTab,
    BTabs,
    BFormTextarea,
    BOverlay,
    BIconController,
    BInputGroup,
    BInputGroupAppend,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      agents: {
        network: 'BNB Smart Chain (BEP20)',
      },
      service_type_kbank: null,
      acc_name_scb: '',
      acc_name_kbank: '',
      acc_kbank: '',
      pin_kbank: '',
      gen_kbank: '',
      step: 1,
      cardId: '',
      dateOfBirth: '',
      MobilePhoneNo: '',
      deviceId: '',
      Auth: '',
      ref: '',
      tokenUUID: '',
      pin: '',
      acc_no: '',
      bank: null,
      show: false,
      banklist: [],
      banklist2: [{ bankcode: '004', name: 'ธนาคารกสิกรไทย' }],
      agent: {},
      selected: { title: '' },
      option: [{ title: 'Square' }],
      options: {
        customDelimiter: {
          delimiters: ['-', '-', '-'],
          blocks: [3, 3, 4],
          uppercase: true,
        },
      },
      autolist: [
        { text: 'ระบบถอน scb', value: 'scb' },
        { text: 'ระบบถอน kbank', value: 'kbank' },
        { text: 'ระบบถอน krungsri', value: 'krungsri' },
        // { text: 'ระบบถอน payment gateway', value: 'payment' },
      ],
      AutoData: {
        prefix: '',
        move_dep1_status: 0,
        move_dep1_amount: 0,
        move_dep1_bank: '',
        move_dep1_acc: '',
        move_dep2_status: 0,
        move_dep2_amount: 0,
        move_dep2_bank: '',
        move_dep2_acc: '',
        move_dep3_status: 0,
        move_dep3_amount: 0,
        move_dep3_bank: '',
        move_dep3_acc: '',
        move_dep4_status: 0,
        move_dep4_amount: 0,
        move_dep4_bank: '',
        move_dep4_acc: '',
        move_crypto_status: 0,
        move_crypto_amount: '',
        move_crypto_acc: '',
      },
      crypto_usdt: 0,
      ShowCheckBank: {
        bankName: '',
        acc: '',
        name: '',
      },
      wit_scb_list: [],
      wit_scb_id: null,
      KbankApi: {
        acc_no: '',
        acc_name: '',
        username: '',
        password: '',
        phone: '',
        status: 0,
      },
      Krungsri: {
        acc_no: '',
        acc_name: '',
        username: '',
        password: '',
        phone: '',
      },
    }
  },
  async mounted() {
    this.getAgent()
    this.getWitScbList()
    // this.getMovemoney()
    this.Get_bank()
  },
  methods: {
    addAdr() {
      this.$swal({
        icon: 'success',
        title: '<h3 style="color: #FFF">คัดลอกสำเร็จ</h3>',
        showConfirmButton: false,
        timer: 1500,
        background: '#202124',
      })
    },
    hide() {
      this.$refs['money-rocker'].hide()
    },
    SubmitGenKbank() {
      this.show = true
      if (this.service_type_kbank === 'deposit') {
        // eslint-disable-next-line vars-on-top, no-var, block-scoped-var
        var path = '/kbankSetting/deposit'
      } else {
        // eslint-disable-next-line vars-on-top, no-var, no-redeclare, block-scoped-var
        var path = '/kbankSetting/withdraw'
      }
      const obj = {
        accountNo: this.acc_kbank,
        pin: this.pin_kbank,
        stateFile: this.gen_kbank,
        acc_name_kbank: this.acc_name_kbank,
      }
      this.$http
        // eslint-disable-next-line no-undef, block-scoped-var
        .post(path, obj)
        // eslint-disable-next-line no-unused-vars
        .then(response => {
          // console.log(response.data)
          this.Success('เชื่อมข้อมูลสำเร็จ')
          this.acc_name_kbank = ''
          this.acc_kbank = ''
          this.pin_kbank = ''
          this.gen_kbank = ''
          this.show = false
        })
        // eslint-disable-next-line no-unused-vars
        .catch(error => {
          this.SwalError('เชื่อมข้อมูลไม่สำเร็จ')
          this.show = false
        })
    },
    SubmitGenKbankApi() {
      this.show = true
      this.$http
        // eslint-disable-next-line no-undef, block-scoped-var
        .post('/agent/update/withdraw/kbiz', this.KbankApi)
        // eslint-disable-next-line no-unused-vars
        .then(response => {
          // console.log(response.data)
          this.Success('เชื่อมข้อมูลสำเร็จ')
          // this.KbankApi = {
          //   acc_no: '',
          //   acc_name: '',
          //   username: '',
          //   password: '',
          //   phone: '',
          // }
          this.show = false
        })
        // eslint-disable-next-line no-unused-vars
        .catch(error => {
          this.SwalError('เชื่อมข้อมูลไม่สำเร็จ')
          this.show = false
        })
    },
    SubmitKrungsriwitdraw() {
      this.show = true
      this.$http
        // eslint-disable-next-line no-undef, block-scoped-var
        .post('/agent/update/withdraw/krungsri', this.Krungsri)
        // eslint-disable-next-line no-unused-vars
        .then(response => {
          // console.log(response.data)
          this.Success('เชื่อมข้อมูลสำเร็จ')
          this.getKrunsri()
          this.show = false
        })
        // eslint-disable-next-line no-unused-vars
        .catch(error => {
          this.SwalError('เชื่อมข้อมูลไม่สำเร็จ')
          this.show = false
        })
    },
    GetDeviceIdScb() {
      this.show = true
      if (this.step === 1) {
        const obj = {
          cardId: this.cardId,
          dateOfBirth: this.dateOfBirth,
          MobilePhoneNo: this.MobilePhoneNo,
        }
        this.$http
          .post('/deviceId/scb/v2', obj)
          .then(response => {
            // console.log(response.data)
            if (response.data.Auth) {
              this.show = false
              this.step = 2
              this.deviceId = response.data.deviceId
              this.Auth = response.data.Auth
              this.ref = response.data.ref
              this.tokenUUID = response.data.tokenUUID
              this.showModal(this.bank)
            }
          })
          .catch(error => {
            this.show = false
            this.SwalError(error.response.data.message.statusdesc)
            this.showModal(this.bank)
          })
      } else {
        const obj = {
          acc_name_scb: this.acc_name_scb,
          acc_no: this.acc_no,
          bank: this.bank,
          phone: this.MobilePhoneNo,
          pin: this.pin,
          otp: this.otp,
          deviceId: this.deviceId,
          Auth: this.Auth,
          ref: this.ref,
          tokenUUID: this.tokenUUID,
        }
        this.$http
          .post('/ConfirmOtp/scb/v2', obj)
          // eslint-disable-next-line no-unused-vars
          .then(response => {
            // console.log(response.data)
            this.Success('เพิ่มข้อมูลโยกเงินสำเร็จ')
            this.acc_name_scb = ''
            this.deviceId = ''
            this.Auth = ''
            this.ref = ''
            this.tokenUUID = ''
            this.MobilePhoneNo = ''
            this.pin = ''
            this.otp = ''
            this.cardId = ''
            this.dateOfBirth = ''
            this.acc_no = ''
            this.show = false
            this.step = 1
          })
          .catch(error => {
            this.step = 1
            this.SwalError(error.response.data.message)
            this.showModal()
            this.show = false
          })
      }
    },
    showModal() {
      this.$refs['my-modal'].show()
    },
    hideModal() {
      this.$refs['my-modal'].hide()
    },
    showModalkbank() {
      this.$refs['my-modal-kbank'].show()
    },
    hideModalkbank() {
      this.$refs['my-modal-kbank'].hide()
    },
    showModalkbankApi() {
      this.$refs['modal-kbank-api'].show()
    },
    showModalKrungsriWithdraw() {
      this.$refs['modal-krungsri-withdraw'].show()
    },
    Get_bank() {
      this.$http
        .get('/bank/list')
        .then(response => {
          this.banklist = response.data
          // console.log(response.data)
        })
        .catch(error => console.log(error))
    },
    async getAgent() {
      this.show = true
      this.$http
        .get('/agent/show')
        .then(response => {
          this.getKbiz()
          this.getKrunsri()
          // console.log('agent')
          this.agent = response.data
          this.getMovemoney()
          this.switch(response.data)

          // console.log(response.data)
        })
        // eslint-disable-next-line no-unused-vars
        .catch(error => {
          this.show = false
        })
    },
    async getMovemoney() {
      this.show = true
      this.$http
        .get('/agent/show/auto')
        .then(response => {
          this.AutoData = response.data
          // console.log('auto')
          this.wit_scb_id = this.AutoData.wit_scb_id
          if (this.AutoData.crypto_address) {
            this.getCryptoBalance()
          }
          // console.log(this.agent)
        })
        // eslint-disable-next-line no-unused-vars
        .catch(error => {
          this.show = false
        })
    },
    async getWitScbList() {
      this.show = true
      this.$http
        .get('/setting/withdrawscb/index')
        .then(response => {
          this.wit_scb_list = response.data
          this.wit_scb_list.forEach((element, index) => {
            this.wit_scb_list[
              index
            ].text = `ชื่อ: ${element.accname} บัญชี: ${element.accno}`
          })
          // console.log(response.data)
        })
        // eslint-disable-next-line no-unused-vars
        .catch(error => {
          this.show = false
        })
    },
    GenCryptoWallet() {
      this.show = true
      this.$http
        .get('/crypto/createwallet')
        .then(response => {
          this.AutoData.crypto_address = response.data
          this.getCryptoBalance()
          this.show = false
        })
        // eslint-disable-next-line no-unused-vars
        .catch(error => {
          this.show = false
        })
    },
    async confirm(banknumber) {
      try {
        this.show = true
        this.AutoData.bank_number = banknumber
        const { data: res } = await this.$http.post(
          '/agent/update/movemoney',
          this.AutoData,
        )
        // console.log(res.status)
        if (res.status) {
          this.show = false
          this.Success('บันทึกข้อมูลสำเร็จ')
        } else {
          this.show = false
          this.SwalError('เกิดข้อผิดพลาด')
        }
      } catch (error) {
        this.show = false
        this.SwalError('เกิดข้อผิดพลาด')
      }
    },
    async UpdateStatusCrypto() {
      try {
        this.show = true
        const { data: res } = await this.$http.post(
          '/agent/update/crypto',
          this.agent,
        )
        // console.log(res.status)
        if (res.status) {
          this.show = false
          this.Success('บันทึกข้อมูลสำเร็จ')
        } else {
          this.show = false
          this.SwalError('เกิดข้อผิดพลาด')
        }
      } catch (error) {
        this.show = false
        this.SwalError('เกิดข้อผิดพลาด')
      }
    },
    getCryptoBalance() {
      const params = {
        walletAddress: this.AutoData.crypto_address,
      }
      this.$http
        .get('/crypto/getbalance', { params })
        .then(response => {
          this.crypto_usdt = response.data.usdt
        })
        .catch(error => console.log(error))
    },
    async checkAcc(banknumber) {
      this.show = true
      if (banknumber === 1) {
        if (!this.agent.dip_bank_accno) {
          this.show = false
          this.$swal('เกิดข้อผิดพลาด', 'ไม่พบข้อมูลบัญชี', 'error')
          return
        }
        const Facc = this.agent.dip_bank_accno.replace(/-/g, '')
        const Tacc = this.AutoData.move_dep1_acc.replace(/-/g, '')
        const Tcode = this.AutoData.move_dep1_bank
        const amount = '1.00'
        const { prefix } = this.AutoData
        const banktype = 'dip1'
        await this.CheckAccNameScb(Facc, Tacc, Tcode, amount, prefix, banktype)
      } else if (banknumber === 2) {
        const Tacc = this.AutoData.move_dep2_acc.replace(/-/g, '')
        const Tcode = this.AutoData.move_dep2_bank
        const amount = '1.00'
        const { prefix } = this.AutoData
        const banktype = 'deposit'
        await this.CheckAccNameKbank(Tcode, Tacc, amount, prefix, banktype)
      } else if (banknumber === 3) {
        if (!this.agent.dip_bank3_accno) {
          this.show = false
          this.$swal('เกิดข้อผิดพลาด', 'ไม่พบข้อมูลบัญชี', 'error')
          return
        }
        const Facc = this.agent.dip_bank3_accno.replace(/-/g, '')
        const Tacc = this.AutoData.move_dep3_acc.replace(/-/g, '')
        const Tcode = this.AutoData.move_dep3_bank
        const amount = '1.00'
        const { prefix } = this.AutoData
        const banktype = 'dip3'
        await this.CheckAccNameScb(Facc, Tacc, Tcode, amount, prefix, banktype)
      }
    },
    async CheckAccNameScb(
      Frombankcode,
      accno,
      Tobankcode,
      amount,
      prefix,
      banktype,
    ) {
      const obj = {
        facc: Frombankcode,
        tacc: accno,
        tcode: Tobankcode,
        amount,
        prefix,
        banktype,
      }
      this.$http
        .post('movemoney/CheckAccNameScb', obj)
        .then(response => {
          this.show = false
          if (response.data.status) {
            this.$refs['money-rocker'].show()
            const Bank = response.data.data
            this.ShowCheckBank.acc = Bank.accountTo
            this.ShowCheckBank.name = Bank.accountToDisplayName
            this.ShowCheckBank.bankName = this.banklist.find(
              bank => bank.bankcode === Tobankcode,
            ).name
          } else {
            this.$swal('เกิดข้อผิดพลาด', response.data.msg, 'error')
          }
        })
        .catch(error => {
          this.show = false
          console.log(error)
          if (error.response) {
            this.$swal('เกิดข้อผิดพลาด', error.response.data.msg, 'error')
          }
        })
    },
    async CheckAccNameKbank(bankcode, accno, amount, prefix, type) {
      const obj = {
        toBankCode: bankcode,
        toAccount: accno,
        amount,
        prefix,
        type,
      }
      this.$http
        .post('movemoney/CheckAccNameKbank', obj)
        .then(response => {
          this.show = false
          console.log(response.data)
          if (response.data.status) {
            this.$refs['money-rocker'].show()
            const Bank = response.data.data
            this.ShowCheckBank.acc = Bank.toAccountNo
            this.ShowCheckBank.name = Bank.toAccountName
            this.ShowCheckBank.bankName = Bank.bankName
          } else {
            this.$swal('เกิดข้อผิดพลาด', response.data.msg, 'error')
          }
        })
        .catch(error => {
          this.show = false
          console.log(error)
          if (error.response) {
            this.$swal('เกิดข้อผิดพลาด', error.response.data.msg, 'error')
          }
        })
    },
    getKbiz() {
      this.$http.get('/agent/show/kbiz').then(response => {
        if (response.data.AutoData) {
          const Data = response.data.AutoData
          this.agent.dip_bank2_username = Data.dip_bank2_username
          this.agent.dip_bank2_password = Data.dip_bank2_password
          this.agent.dip_bank4_username = Data.dip_bank4_username
          this.agent.dip_bank4_password = Data.dip_bank4_password

          this.KbankApi.acc_no = Data.wit_bank1_accno
          this.KbankApi.acc_name = Data.wit_bank1_accname
          this.KbankApi.username = Data.wit_bank1_username
          this.KbankApi.password = Data.wit_bank1_password
          this.KbankApi.phone = Data.wit_bank1_sms
        }
        if (response.data.AutoWitBot) {
          const Data = response.data.AutoWitBot
          this.KbankApi.status = Data.status
        }
        this.show = false
      })
    },
    getKrunsri() {
      this.$http
        .get('/agent/show/krungsri')
        .then(response => {
          if (response.data.data) {
            const Data = response.data.data
            this.Krungsri.acc_no = Data.wit_bank3_accno
            this.Krungsri.acc_name = Data.wit_bank3_accname
            this.Krungsri.username = Data.wit_bank3_username
            this.Krungsri.password = Data.wit_bank3_password
            this.Krungsri.phone = Data.wit_bank3_sms
          }
          this.show = false
        })
    },
    switch(data) {
      if (data.status_dip === '1') {
        this.agent.status_dip = true
      }
      if (data.status_wit === '1') {
        this.agent.status_wit = true
      }
      if (data.status_auto_dip === '1') {
        this.agent.status_auto_dip = true
      }
      if (data.status_auto_wit === '1') {
        this.agent.status_auto_wit = true
      }
      if (data.dep_sms === '1' || data.dep_sms === 1) {
        this.agent.dep_sms = true
      }
      if (data.dep_connect === '1' || data.dep_connect === 1) {
        this.agent.dep_connect = true
      }
      if (data.maintenance === '1') {
        this.agent.maintenance = true
      }
      // eslint-disable-next-line eqeqeq
      if (data.clearuser == 1) {
        this.agent.clearuser = true
      }
      if (data.checkbank1 === 1) {
        this.agent.checkbank1 = true
      }
      if (data.checkbank2 === 1) {
        this.agent.checkbank2 = true
      }
      if (data.checkbank3 === 1) {
        this.agent.checkbank3 = true
      }
      if (data.checkbank4 === 1) {
        this.agent.checkbank4 = true
      }
      if (data.checktruewallet === 1) {
        this.agent.checktruewallet = true
      }
      if (data.alarm === 1) {
        this.agent.alarm = true
      }
      if (data.status_checkname === 1) {
        this.agent.status_checkname = true
      }
      if (data.autowithdraw_payment_status === 1) {
        this.agent.autowithdraw_payment_status = true
      }
    },
    submit() {
      if (this.agent.id) {
        if (this.agent.alarm === true) {
          this.agent.alarm = 1
        }
        if (this.agent.status_checkname === true) {
          this.agent.status_checkname = 1
        }
        if (this.wit_scb_id) {
          this.agent.wit_scb_id = this.wit_scb_id
        }
        this.$http
          .post('/agent/update', this.agent)
          .then(() => {
            this.getAgent()
            this.Success('แก้ไขข้อมูล สำเร็จ')
          })
          .catch(error => {
            this.SwalError(error.response.data.message)
          })
      } else {
        this.$http
          .post('/agent/store', this.agent)
          .then(() => {
            this.getAgent()
            this.Success('เพิ่มข้อมูล สำเร็จ')
          })
          .catch(error => {
            this.SwalError(error.response.data.message)
          })
      }
    },
    Commas(x) {
      if (Math.round(x) !== x && x > 0) {
        const Num = Number(x)
        const y = Num.toFixed(2)
        return y.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      }
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    },
    Success(mes) {
      this.$swal({
        icon: 'success',
        title: '<h3 style="color: #141414">ทำรายการสำเร็จ</h3>',
        text: mes,
        customClass: {
          confirmButton: 'btn btn-success',
        },
      })
    },
    SwalError(mes) {
      this.$swal({
        icon: 'error',
        title: '<h3 style="color: #141414">เกิดข้อผิดพลาด!</h3>',
        text: mes,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
      })
    },
  },
}
</script>

<style scoped>
.card-credit {
  background: #e9e9e9;
  border-radius: 0.25rem;
  padding: 1.5rem;
  margin-bottom: 1rem;
}
.g-status {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-size: 1rem;
  font-weight: bold;
  color: #fff;
  border-radius: 0.25rem;
  padding: 2.35rem;
  margin-bottom: 1rem;
}
.g-status.success {
  background: linear-gradient(to right, #3dc92a, #00770a);
}
.g-status.error {
  background: linear-gradient(to right, #ff5b5b, #c70000);
}
.g-status.warning {
  background: linear-gradient(to right, #ffb800, #ff8a00);
}
.g-status.info {
  background: linear-gradient(to right, #00b0ff, #0078ff);
}
.g-status.secondary {
  background: linear-gradient(to right, #797979, #424547);
}

i {
  color: #2e5d92;
}

.ag-courses_box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.ag-courses_item {
  -ms-flex-preferred-size: calc(20% - 30px);
  flex-basis: calc(20% - 30px);

  margin: 0 10px 20px;

  overflow: hidden;

  border-radius: 28px;
}
.ag-courses-item_link {
  display: block;
  padding: 30px 20px;
  background-color: #121212;

  overflow: hidden;

  position: relative;
}
.ag-courses-item_link:hover,
.ag-courses-item_link:hover .ag-courses-item_date {
  text-decoration: none;
  color: #fff;
}
.ag-courses-item_link:hover .ag-courses-item_bg {
  -webkit-transform: scale(10);
  -ms-transform: scale(10);
  transform: scale(10);
}
.ag-courses-item_title {
  min-height: 37px;
  margin: 0 0 25px;

  overflow: hidden;

  font-weight: bold;
  font-size: 16px;
  color: #fff;

  z-index: 2;
  position: relative;
}
.ag-courses-item_date-box {
  font-size: 18px;
  color: #fff;

  z-index: 2;
  position: relative;
}
.ag-courses-item_date {
  font-weight: bold;
  color: #f9b234;

  -webkit-transition: color 0.5s ease;
  -o-transition: color 0.5s ease;
  transition: color 0.5s ease;
}
.ag-courses-item_bg {
  height: 128px;
  width: 128px;
  background-color: #f9b234;

  z-index: 1;
  position: absolute;
  top: -75px;
  right: -75px;

  border-radius: 50%;

  -webkit-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
}
.ag-courses_item:nth-child(2n) .ag-courses-item_bg {
  background-color: #3ecd5e;
}
.ag-courses_item:nth-child(3n) .ag-courses-item_bg {
  background-color: #e44002;
}
.ag-courses_item:nth-child(4n) .ag-courses-item_bg {
  background-color: #952aff;
}
.ag-courses_item:nth-child(5n) .ag-courses-item_bg {
  background-color: #cd3e94;
}
.ag-courses_item:nth-child(6n) .ag-courses-item_bg {
  background-color: #4c49ea;
}

@media only screen and (max-width: 979px) {
  .ag-courses_item {
    -ms-flex-preferred-size: calc(50% - 30px);
    flex-basis: calc(50% - 30px);
  }
  .ag-courses-item_title {
    font-size: 16px;
  }
}

@media only screen and (max-width: 767px) {
  .ag-format-container {
    width: 96%;
  }
}
@media only screen and (max-width: 639px) {
  .ag-courses_item {
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
  }
  .ag-courses-item_title {
    min-height: 30px;
    line-height: 1;

    font-size: 16px;
  }
  .ag-courses-item_link {
    padding: 22px 40px;
  }
  .ag-courses-item_date-box {
    font-size: 16px;
  }
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
